<template>
  <div class="mb-7">
    <h3>
      {{ fieldDefinition && $t(`document.documentRecording.${fieldDefinition.label.replace(/\s+/g, '')}`) }}
      <el-form-item :prop="fieldKey" :rules="rule" />
    </h3>
    <div class="card table-responsive p-2">
      <table class="charges-table">
        <thead>
          <tr>
            <td v-if="generalChargesStructureParams?.name">
              <p class="fw-bold">{{ $t('document.exports.schema.generalChargesFields.name') }}</p>
            </td>
            <td v-if="generalChargesStructureParams?.amount">
              <p class="fw-bold">{{ $t('document.exports.schema.generalChargesFields.amount') }}</p>
            </td>
            <td style="width: 30px"></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(charge, index) in value" :key="arrayKeys[index]">
            <td v-if="generalChargesStructureParams?.name">
              <autoinput :key="`${fieldKey}.${index}.name`" :field-key="`${fieldKey}.${index}.name`" />
            </td>
            <td v-if="generalChargesStructureParams?.amount">
              <autoinput :key="`${fieldKey}.${index}.amount`" :field-key="`${fieldKey}.${index}.amount`" />
            </td>
            <td class="align-middle text-center">
              <el-dropdown
                trigger="click"
                :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
                @command="(method) => _self[method](index)"
              >
                <Button type="icon" class="p-0">
                  <KebabIcon width="24px" height="24px" />
                </Button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="addItemRowAbove">
                    {{ $t('document.documentRecording.addRowAbove') }}
                  </el-dropdown-item>
                  <el-dropdown-item command="removeItem">
                    {{ $t('document.documentRecording.removeRow') }}
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </td>
          </tr>

          <tr :key="nextRandomKey">
            <td>
              <el-form-item class="mb-0">
                <el-input class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td>
              <el-form-item class="mb-0">
                <input-money class="w-100" @focus="convertItem" />
              </el-form-item>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script type="text/javascript">
import Autoinput from '@/modules/templates/components/autoform/autoinput';
import AutoformArray from '@/modules/templates/components/autoform/autoform_array';
import { KebabIcon } from '@/assets/icons';
import { Button } from '@/modules/core';
import InputMoney from '../InputMoney';

export default {
  components: {
    Autoinput,
    KebabIcon,
    Button,
    InputMoney,
  },
  extends: AutoformArray,
  props: {
    generalChargesStructureParams: { type: Object, required: false, default: () => ({}) },
  },

  data() {
    return { nextRandomKey: Math.random() };
  },
  methods: {
    async convertItem() {
      const focusedInput = this.$el.querySelector('input:focus');
      const allInputs = Array.from(this.$el.querySelectorAll('input'));
      const focusIndex = allInputs.indexOf(focusedInput);

      this.addItem(this.nextRandomKey);
      this.nextRandomKey = Math.random();

      await this.$nextTick();
      const newInput = Array.from(this.$el.querySelectorAll('input'))[focusIndex];
      newInput.focus();
    },
  },
};
</script>
<style lang="scss" scoped>
.charges-table {
  ::v-deep td {
    padding: 5px;
  }
}
</style>
