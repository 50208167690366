<template>
  <tr v-if="itemModel">
    <td class="text-center align-middle p-0">
      <autoinput :key="`${fieldKey}.${index}`" :field-key="`${fieldKey}.${index}`" />
    </td>
    <td v-if="itemsParams.reference">
      <autoinput :key="`${fieldKey}.${index}.reference`" :field-key="`${fieldKey}.${index}.reference`" />
    </td>
    <td v-if="itemsParams.sku">
      <autoinput :key="`${fieldKey}.${index}.sku`" :field-key="`${fieldKey}.${index}.sku`" />
    </td>
    <td v-if="itemsParams.gtin">
      <autoinput :key="`${fieldKey}.${index}.gtin`" :field-key="`${fieldKey}.${index}.gtin`" />
    </td>
    <td v-if="itemsParams.name">
      <autoinput :key="`${fieldKey}.${index}.name`" :field-key="`${fieldKey}.${index}.name`" />
    </td>
    <td v-if="itemsParams.quantity">
      <autoinput
        :key="`${fieldKey}.${index}.quantity`"
        :field-key="`${fieldKey}.${index}.quantity`"
        class="direction"
      />
    </td>
    <td v-if="itemsParams.packageQuantity">
      <autoinput
        :key="`${fieldKey}.${index}.packageQuantity`"
        :field-key="`${fieldKey}.${index}.packageQuantity`"
        class="direction"
      />
    </td>
    <td v-if="itemsParams.quantityInPackage">
      <autoinput
        :key="`${fieldKey}.${index}.quantityInPackage`"
        :field-key="`${fieldKey}.${index}.quantityInPackage`"
        class="direction"
      />
    </td>
    <td v-if="itemsParams.price">
      <autoinput :key="`${fieldKey}.${index}.price`" :field-key="`${fieldKey}.${index}.price`" class="direction" />
    </td>
    <td v-if="itemsParams.discountRate">
      <autoinput
        :key="`${fieldKey}.${index}.discountRate`"
        :field-key="`${fieldKey}.${index}.discountRate`"
        class="direction"
      >
        <el-form-item slot-scope="scope" :prop="scope.fieldKey" :rules="scope.rule" :show-message="false" class="mb-0">
          <el-input :value="scope.value" type="number" class="w-100 direction" @input="scope.input" />
        </el-form-item>
      </autoinput>
    </td>
    <td v-if="itemsParams.discountAmount">
      <autoinput
        :key="`${fieldKey}.${index}.discountAmount`"
        :field-key="`${fieldKey}.${index}.discountAmount`"
        class="direction"
      >
        <el-form-item
          slot-scope="{ fieldKey: childKey, value, input, rule }"
          :prop="childKey"
          :rules="rule"
          :show-message="false"
          class="mb-0"
        >
          <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        </el-form-item>
      </autoinput>
    </td>
    <td v-if="itemsParams.totalDiscount">
      <autoinput
        :key="`${fieldKey}.${index}.totalDiscount`"
        :field-key="`${fieldKey}.${index}.totalDiscount`"
        class="direction"
      >
        <el-form-item
          slot="items.$.totalDiscount"
          slot-scope="{ fieldKey: childKey, value, input, rule }"
          :prop="childKey"
          :rules="rule"
          :show-message="false"
          class="mb-0"
        >
          <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        </el-form-item>
      </autoinput>
    </td>
    <td v-if="itemsParams.netPrice">
      <autoinput :key="`${fieldKey}.${index}.netPrice`" :field-key="`${fieldKey}.${index}.netPrice`" class="direction">
        <el-form-item
          slot-scope="{ fieldKey: childKey, value, input, rule }"
          :prop="childKey"
          :rules="rule"
          :show-message="false"
          class="mb-0"
        >
          <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        </el-form-item>
      </autoinput>
    </td>
    <td v-if="itemsParams.totalTax">
      <autoinput :key="`${fieldKey}.${index}.totalTax`" :field-key="`${fieldKey}.${index}.totalTax`" class="direction">
        <el-form-item
          slot-scope="{ fieldKey: childKey, value, input, rule }"
          :prop="childKey"
          :rules="rule"
          :show-message="false"
          class="mb-0"
        >
          <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        </el-form-item>
      </autoinput>
    </td>
    <td v-if="itemsParams.totalPackages">
      <autoinput
        :key="`${fieldKey}.${index}.totalPackages`"
        :field-key="`${fieldKey}.${index}.totalPackages`"
        class="direction"
      >
        <el-form-item
          slot-scope="{ fieldKey: childKey, value, input, rule }"
          :prop="childKey"
          :rules="rule"
          :show-message="false"
          class="mb-0"
        >
          <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        </el-form-item>
      </autoinput>
    </td>
    <td v-if="itemsParams.totalDeposits">
      <autoinput
        :key="`${fieldKey}.${index}.totalDeposits`"
        :field-key="`${fieldKey}.${index}.totalDeposits`"
        class="direction"
      >
        <el-form-item
          slot-scope="{ fieldKey: childKey, value, input, rule }"
          :prop="childKey"
          :rules="rule"
          :show-message="false"
          class="mb-0"
        >
          <input-money :show-icon="false" :value="value" class="w-100" @input="input" />
        </el-form-item>
      </autoinput>
    </td>
    <td v-if="itemsParams.totalAmount">
      <autoinput
        :key="`${fieldKey}.${index}.totalAmount`"
        :field-key="`${fieldKey}.${index}.totalAmount`"
        class="direction"
      />
    </td>
    <td class="align-middle text-center">
      <el-dropdown
        trigger="click"
        :placement="$t('direction') === 'rtl' ? 'bottom-start' : 'bottom-end'"
        @command="(method) => _self[method]()"
      >
        <Button type="icon" class="p-0">
          <KebabIcon width="24px" height="24px" />
        </Button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="addNewProduct" :disabled="!!itemModel.productId">
            {{ $t('document.documentRecording.addNewProduct') }}
          </el-dropdown-item>
          <el-dropdown-item command="addItemAbove">
            {{ $t('document.documentRecording.addRowAbove') }}
          </el-dropdown-item>
          <el-dropdown-item command="removeItem">
            {{ $t('document.documentRecording.removeRow') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </td>
  </tr>
  <tr v-else @focusin="$emit('add')">
    <td class="text-center align-middle">
      <i class="el-icon-circle-plus" @click="$emit('add')" />
    </td>
    <td v-if="itemsParams.reference">
      <el-form-item class="mb-0">
        <el-input value="" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.sku">
      <el-form-item class="mb-0">
        <el-input value="" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.gtin">
      <el-form-item class="mb-0">
        <el-input value="" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.name">
      <el-form-item class="mb-0">
        <el-input value="" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.packageQuantity">
      <el-form-item class="mb-0">
        <el-input type="number" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.quantityInPackage">
      <el-form-item class="mb-0">
        <el-input type="number" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.quantity">
      <el-form-item class="mb-0">
        <el-input type="number" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.price">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.discountRate">
      <el-form-item class="mb-0">
        <el-input type="number" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.discountAmount">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.totalDiscount">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.netPrice">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.totalTax">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.totalPackages">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.totalDeposits">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td v-if="itemsParams.totalAmount">
      <el-form-item class="mb-0">
        <input-money :show-icon="false" class="w-100" />
      </el-form-item>
    </td>
    <td></td>
  </tr>
</template>

<script type="text/javascript">
import Autoinput from '@/modules/templates/components/autoform/autoinput';
import { KebabIcon } from '@/assets/icons';
import { Button } from '@/modules/core';

import InputMoney from '../InputMoney';

export default {
  components: { Autoinput, InputMoney, KebabIcon, Button },
  props: {
    fieldKey: { type: String, required: true },
    index: { type: Number, required: true },
    itemModel: { type: Object, default: null },
    itemsParams: { type: Object, required: true },
  },
  methods: {
    removeItem() {
      return this.$emit('remove');
    },
    addItemAbove() {
      return this.$emit('add-item-above');
    },
    addNewProduct() {
      return this.$emit('create-item');
    },
  },
};
</script>

<style scoped>
.direction {
  direction: initial;
}
</style>
