<template>
  <el-form-item :prop="fieldKey" :rules="rule">
    <InputMoney v-model="model" :placeholder="fieldDefinition && fieldDefinition.label" class="w-100" />
  </el-form-item>
</template>

<script type="text/javascript">
import AutoformOther from '@/modules/templates/components/autoform/autoform_other';
import InputMoney from './InputMoney.vue';
export default {
  components: {
    InputMoney,
  },
  extends: AutoformOther,
};
</script>
